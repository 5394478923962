import { useQuery } from 'villus'

import { DeviceMenuDocument, type DeviceType } from '@/graphql/creator-platform/generated'
import { getCurrentLocale } from '@/locale'
import { sentry } from '@/utils/sentry'

import { type BrandItem, type Devices } from './types'

export async function getDeviceMenu(type: DeviceType): Promise<Devices | null> {
  const variables = {
    locale: getCurrentLocale(),
    type,
  }
  const { data, error } = await useQuery({
    query: DeviceMenuDocument,
    variables,
  })
  if (data.value === null || error.value !== null) {
    return null
  }
  const deviceMenuData = data.value.deviceMenu ?? null
  if (deviceMenuData === null) {
    sentry.error('backend device menu is empty')
    return null
  }

  const deviceMenu = new Map<string, BrandItem>()
  for (const brandItem of deviceMenuData.brands) {
    const brandList = {
      title: brandItem.title,
      devices: new Map(),
    }
    for (const deviceItem of brandItem.devices) {
      brandList.devices.set(deviceItem.deviceHandle, {
        title: deviceItem.title,
        colorForImages: new Map(
          deviceItem.deviceImagesWithColor.map(({ colorCode, imageUrl }) => [colorCode, imageUrl]),
        ),
      })
    }

    if (brandList.devices.size !== 0) {
      deviceMenu.set(brandItem.brandHandle, brandList)
    }
  }

  return deviceMenu
}
