import { defineStore } from 'pinia'
import { ref } from 'vue'

interface AlertOption {
  content: string
  time?: number
}

const defaultTime = 3000

export const useAlertStore = defineStore('alert', () => {
  const currentAlert = ref<AlertOption | null>(null)
  let timeoutItem: null | number = null

  const modalMessage = ref<null | string>(null)

  function setModalMessage(message: null | string): void {
    modalMessage.value = message
  }

  function setAlert(_alert: AlertOption): void {
    clearAlert()
    currentAlert.value = _alert
    if (_alert.time !== 0) {
      timeoutItem = window.setTimeout(clearAlert, _alert.time ?? defaultTime)
    }
  }
  function clearAlert(): void {
    if (timeoutItem !== null) {
      window.clearTimeout(timeoutItem)
    }
    currentAlert.value = null
  }

  return {
    setAlert,
    clearAlert,
    setModalMessage,
    currentAlert,
    modalMessage,
  }
})
