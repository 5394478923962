import * as cookie from 'cookie'
import typia from 'typia'
import { onMounted } from 'vue'

import { type Locale } from '@/graphql/creator-platform/generated'
import { getCurrentLocale, shopifyTokenList } from '@/locale'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __st: {
      cid: number | undefined
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    gdpr_loaded: number | undefined
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Shopify: {
      shop: string
    }
  }
}

type GdprLocale = Extract<
  Locale,
  Locale.De | Locale.Es | Locale.Eu | Locale.Fr | Locale.Io | Locale.Th | Locale.Uk
>

const locale = getCurrentLocale()
const consentMoConsentStatusKey = 'cookieconsent_status'

function initCookiesIfNotExist(): boolean {
  const cookies = cookie.parse(document.cookie)
  const consentStatus = cookies[consentMoConsentStatusKey]

  // If the user has not set this for GDPR, the cookie value will be undefined
  const isGdprConfigured = consentStatus !== undefined

  return isGdprConfigured
}

export default function useGdpr(): void {
  if (typia.is<GdprLocale>(locale)) {
    const isGdprConfigured = initCookiesIfNotExist()
    if (!isGdprConfigured) {
      onMounted(() => {
        injectGdprAppScript(locale)
      })
    }
  }

  function injectGdprAppScript(locale: GdprLocale): void {
    const shopifyDomain = shopifyTokenList[locale].shopifyDomain

    window.Shopify = { shop: shopifyDomain }
    // eslint-disable-next-line no-void
    window.gdpr_loaded = void 0
    // eslint-disable-next-line no-void
    window.__st = { cid: void 0 }

    const script = document.createElement('script')
    script.defer = true
    script.src = `https://gdprcdn.b-cdn.net/js/gdpr_cookie_consent.min.js?shop=${shopifyDomain}`
    script.onload = () => {
      addWatcher()
    }
    document.head.appendChild(script)
  }

  const SUBMIT_BUTTONS_COMMON_CLASS = 'isense-cc-submit-consent'
  const CONTAINERS_CLASSES = [
    'cc-window', // FAB on the bottom
    'cc-settings-view', // pop-up settings
  ]

  /**
   * watch GDPR elements is created or not
   */
  function addWatcher(): void {
    const submitButtons: Element[] = []
    const observer = new MutationObserver(() => {
      const gdprElements = CONTAINERS_CLASSES.map((className) =>
        document.querySelector(`.${className}`),
      )

      if (gdprElements.every((element) => Boolean(element))) {
        submitButtons.push(...document.querySelectorAll(`.${SUBMIT_BUTTONS_COMMON_CLASS}`))
        submitButtons.forEach((element) => {
          element.addEventListener('click', onSubmit)
        })

        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
    })

    function onSubmit(event: Event): void {
      const element = event.target as HTMLElement

      if (element.classList.contains(SUBMIT_BUTTONS_COMMON_CLASS)) {
        submitButtons.forEach((element) => {
          element.removeEventListener('click', onSubmit)
        })
      }
    }
  }
}
