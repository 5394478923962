export enum Route {
  ArtistPage = '/artist/:id',
  ArtistsPage = '/artists',
  BackgroundPage = '/background/:id',
  CheckoutPage = '/checkout',
  CollagePage = '/collage/:id',
  DesignArtistPage = '/design/:idOrHandle',
  DesignPage = '/design',
  DevelopPage = '/dev',
  FavoriteArtworkPage = '/favorite-artwork',
  GalleryPage = '/gallery',
  Root = '/',
  StickerGroupPage = '/sticker-group/:id',
}
