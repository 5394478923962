import '@unocss/reset/tailwind.css'
import '@evolutivelabs/amuse-design-canvas/style.css'
import '@evolutivelabs/amuse-ui/style.css'
import 'reflect-metadata'
import 'virtual:uno.css'

import { plugin as auDesignCanvas, type FontFaceRule } from '@evolutivelabs/amuse-design-canvas'
import * as Sentry from '@sentry/vue'
import i18nextVue from 'i18next-vue'
import { createPinia } from 'pinia'
import { createApp, type DirectiveBinding } from 'vue'

import i18next from '@/i18nConfig'
import { getCurrentIsProduction, getNodeENV, HostEnv } from '@/locale'
import { fallbackFonts, fonts } from '@/materials/fonts.config'
import { router } from '@/router'
import { cleanTranslation } from '@/utils/common'
import shopifyClient from '@/utils/ShopifyService'
import { LocalStorage } from '@/utils/storage'
import { villusClient } from '@/utils/villus'

import App from './App.vue'
import { useProductStore } from './store/productStore'

const pinia = createPinia()

const app = createApp(App)
app.use(i18nextVue, { i18next })
app.use(villusClient)
app.use(router)
app.use(pinia)

app.use(auDesignCanvas, {
  fontUtil: {
    fallbackFonts,
    minFontSize: 12,
    // eslint-disable-next-line @typescript-eslint/require-await
    fontSourceMap: async () =>
      new Map<string, FontFaceRule[]>(
        Object.entries(fonts).map(([key, value]) => {
          return [key, [{ src: value }]]
        }),
      ),
  },
  designStorage: {
    load: () => localStorage.getItem(LocalStorage.DesignConfig),
    save: (value: string) => {
      localStorage.setItem(LocalStorage.DesignConfig, value)
      const productStore = useProductStore()
      localStorage.setItem(
        LocalStorage.DesignBindDesignGroupType,
        productStore.bindingDesignGroup?.type ?? '',
      )
    },
  },
})

app.directive('clean-html', (el: Element, binding: DirectiveBinding<string>) => {
  el.innerHTML = cleanTranslation(binding.value)
})

interface HTMLElementWithClickOutside extends HTMLElement {
  clickOutsideEvent: (event: Event) => void
}
app.directive('click-outside', {
  beforeMount(el: HTMLElementWithClickOutside, binding: DirectiveBinding<() => void>) {
    el.clickOutsideEvent = function (event) {
      if (!(event.target instanceof Node)) return
      if (!el.contains(event.target)) {
        binding.value()
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el: HTMLElementWithClickOutside) {
    document.removeEventListener('click', el.clickOutsideEvent)
  },
})

function getSentrySampleRate(): number {
  return getCurrentIsProduction() ? 0.1 : 1
}
if ([HostEnv.Production, HostEnv.Staging].includes(getNodeENV())) {
  Sentry.init({
    app,
    dsn: 'https://2485ff49b6ef4f3f84a1e02e837cc3bd@o4505356853575680.ingest.sentry.io/4505361149198336',
    environment: getNodeENV(),
    integrations: [
      // Docs: https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations/vue-router/
      Sentry.browserTracingIntegration({ router }),

      // Docs: https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations/plugin/#httpclient
      Sentry.httpClientIntegration(),
    ],
    // Docs: https://docs.sentry.io/platforms/javascript/guides/nextjs/performance/
    tracesSampleRate: 0.01,
    // Docs: https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/sampling/#sampling-error-events
    sampleRate: getSentrySampleRate(),
    // Docs: https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-nameignore-errors-
    ignoreErrors: [
      /get shopify checkout data failed/,
      '[Network] Failed to fetch',
      'Non-Error promise rejection captured with value: load image failed',
      'isOperating',
      'isLocked',
    ],
  })
  void shopifyClient.getCheckoutIdAndSendToSentry()
}

app.mount('#app')
