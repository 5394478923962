<template>
  <router-view
    :key="$route.path"
    class="max-w-1440px bg-white md:m-auto"
    :class="{
      'md:max-w-screen-md': !layoutStore.isRwdPage,
      scrollbar: layoutStore.isDesktop,
    }"
    oncontextmenu="return false"
    @dblclick.stop
  />
  <ModalDialog v-model:visible="showUnsupportedAlert" hide-close-button>
    <template #content>
      <p v-clean-html="$t('message.unsupported_warning')" />
    </template>
    <template #footer>
      <AuButton @click="redirectToGooglePlay"> {{ $t('btn.go_google_play') }} </AuButton>
    </template>
  </ModalDialog>
  <ModalDialog :visible="alertStore.modalMessage !== null" @hide="alertStore.setModalMessage(null)">
    <template #content>
      <p class="whitespace-pre-wrap content-14-h24-r text-primary-80">
        {{ alertStore.modalMessage }}
      </p>
    </template>
    <template #footer>
      <AuButton type="primary-fill" @click="alertStore.setModalMessage(null)">
        {{ $t('btn.ok') }}
      </AuButton>
    </template>
  </ModalDialog>
  <ToastMessage />
</template>

<script setup lang="ts">
import 'swiper/css'

import { AuButton } from '@evolutivelabs/amuse-ui'
import { t } from 'i18next'
import { onMounted } from 'vue'

import ModalDialog from '@/components/ModalDialog.vue'
import ToastMessage from '@/components/ToastMessage.vue'
import { Locale } from '@/graphql/creator-platform/generated'
import { setCssVariable, useLayoutStore } from '@/store/layoutStore'
import { useProductNavigationStore } from '@/store/productNavigationStore'
import { isUnsupportedBrowser } from '@/utils/common'
import { pushGA4ButtonClick } from '@/utils/googleAnalytics'
import { setMetadata } from '@/utils/openGraphMeta'
import { getQueryStringDiscountCode, setQueryStringDiscountCode } from '@/utils/queryString'
import shopifyClient from '@/utils/ShopifyService'
import useGdpr from '@/utils/useGdpr'
import { useSpeedUpImageGeneration } from '@/utils/useSpeedUpImageGeneration'

import { getCurrentLocale } from './locale'
import { useAlertStore } from './store/alertStore'

document.title = t('header.title')
setMetadata('og:title', t('header.title'))
setMetadata('og:description', t('header.description'))

// 為了讓裡面的 onMounted 可以正常觸發，至少要讓他 initial
useLayoutStore()
// 也是為了讓每一頁進去產品可以觸發，所以在這邊讓他 run 一下 init function
useProductNavigationStore()
const layoutStore = useLayoutStore()
const showUnsupportedAlert = isUnsupportedBrowser()
function redirectToGooglePlay(): void {
  pushGA4ButtonClick('redirect to Google Play')
  window.location.replace('https://play.google.com/store/apps/details?id=com.android.chrome')
}

const locale = getCurrentLocale()
if (locale === Locale.Jp) {
  setCssVariable('--display-font', 'Noto Sans JP, sans-serif')
} else if (locale === Locale.Th) {
  setCssVariable('--display-font', 'Noto Sans Thai, sans-serif')
} else {
  setCssVariable('--display-font', 'Noto Sans TC, sans-serif')
}

async function autoBringInDiscountCode(): Promise<void> {
  const discountCode = getQueryStringDiscountCode()
  if (discountCode !== '' && discountCode !== null) {
    await shopifyClient.addDiscount(discountCode)
    await setQueryStringDiscountCode(null)
  }
}

const alertStore = useAlertStore()

onMounted(async () => {
  await autoBringInDiscountCode()
})

useGdpr()
useSpeedUpImageGeneration()
</script>

<style>
/* 這是 css variable 的 default 值，如果執行完 setCssVariable 這邊的資料會被蓋掉 */
html {
  --real1vh: 1vh;
  --empty-area-width: 0px;
}

/* 防止頁面被拖動 */
body {
  @apply fixed w-100vw font-sans bg-bg-gray;
}

img {
  /* 鎖掉 safari 長按圖片儲存，非標準，不是全部裝置都有效 */
  -webkit-touch-callout: none;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  /* firefox 無效，但已被 ban
   * IE 使用率已很低
   * https://caniuse.com/mdn-css_properties_-webkit-user-drag
   * 更完美的方式是用 HTMLElement API draggable 但需要每個 img 都要寫容易忘記 (https://caniuse.com/mdn-api_htmlelement_draggable)
   * 所以用 css 寫入會比較好
   */
  user-drag: none;
  -webkit-user-drag: none;
}

.scrollbar {
  @apply scrollbar-rounded;
}

/* 用各種方式隱藏 zendesk 的 button，避免突然被客服雷到，客服的 chat bot 不知道什麼時候會偷上線 */
#chat-float-button {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
  width: 0px !important;
  height: 0px !important;
}
</style>
