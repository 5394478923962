// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/naming-convention */
import typia from 'typia'
import { type Router } from 'vue-router'

import { type ChipData } from '@/components/TagChip.type'
import {
  type ArtworkType,
  BoundDeviceCategoryType,
  UnboundDeviceCategoryType,
} from '@/graphql/creator-platform/generated'
import {
  type BoundDeviceProductState,
  type ProductStateWithSelectedProduct,
} from '@/store/newProductStore.type'
import { isArtworkType } from '@/utils/common'

import { sentry } from './sentry'

let router: null | Router = null

// TODO(carl): 刪除這段 migration 之前要記得做 get_design 小工具的 migration
const productHandleMigrationMap = new Map<string, string>([
  ['airpods-case', 'airpods-case-bundle-front'],
  ['airtag-case', 'airtag-case'],
  [
    'aquastand-stainless-steel-480ml-without-straw',
    'aquastand-stainless-steel-480ml-without-straw',
  ],
  ['aquastand-stainless-steel-700ml-with-straw', 'aquastand-stainless-steel-700ml-with-straw'],
  [
    'aquastand-stainless-steel-700ml-without-straw',
    'aquastand-stainless-steel-700ml-without-straw',
  ],
  ['aquastand-tritan-800ml-with-straw', 'aquastand-tritan-800ml-with-straw'],
  ['clear-case', 'clear-case-bumper'],
  ['clear-case-capture-button', 'clear-case-bumper-with-capture-button'],
  ['clear-case-magsafe', 'clear-case-magsafe-bumper'],
  ['clear-case-magsafe-capture-button', 'clear-case-magsafe-bumper-with-capture-button'],
  ['grip-max-adhesive', 'grip-max-adhesive'],
  ['grip-max-magsafe', 'grip-max-magsafe'],
  ['grip-max-magsafe-mirror', 'grip-max-magsafe-mirror'],
  ['grip-mini-adhesive', 'grip-mini-adhesive'],
  ['grip-o-magsafe', 'grip-o-magsafe'],
  ['ipad-case', 'ipad-case-bumper'],
  ['jellytint', 'jellytint-bumper'],
  ['jellytint-capture-button', 'jellytint-bumper-with-capture-button'],
  ['jellytint-magsafe', 'jellytint-magsafe-bumper'],
  ['jellytint-magsafe-capture-button', 'jellytint-magsafe-bumper-with-capture-button'],
  ['mod-nx', 'mod-nx-bumper'],
  ['mod-nx-backplate-only', 'mod-nx-backplate'],
  ['mod-nx-magsafe', 'mod-nx-magsafe-bumper'],
  ['mod-nx-magsafe-backplate-only', 'mod-nx-magsafe-backplate'],
  ['solidsuit', 'solidsuit-bumper-back'],
  ['solidsuit-capture-button', 'solidsuit-bumper-back-with-capture-button'],
  ['solidsuit-magsafe', 'solidsuit-magsafe-bumper-back'],
  ['solidsuit-magsafe-capture-button', 'solidsuit-magsafe-bumper-back-with-capture-button'],
  ['solidsuit-tpe', 'solidsuit-bumper-back'],
])

export enum QueryString {
  /**
   * 主要給 artiest page 跟 gallery page 導頁用
   */
  ArtworkType = 'artwork_type',
  /**
   * 為了雲端發票存在的 query string
   */
  CarrierBarcodeImageUrl = 'carrier_barcode_image_url',
  Design = 'design',
  Device = 'device',

  DeviceColor = 'device_color',
  DeviceHandle = 'device_handle',
  /**
   * 折扣碼
   */
  DiscountCode = 'discount_code',
  /**
   * 使用外部圖片
   */
  ForeignPhotoUrl = 'foreign_photo_url',
  /**
   * 關鍵字搜尋
   */
  Keyword = 'keyword',
  Product = 'product',
  ProductColor = 'product_color',
  ProductHandle = 'product_handle',
  ProductNavigation = 'product_navigation',
  ProductType = 'product_type',
  /**
   * 分享設計用的，value 帶 uuid，會從後端拉回設計的 config 倒入到 local storage 裡面
   */
  ShareDesign = 'share_design',
  /**
   * gallery page 分享 tags
   */
  Tags = 'tags',
}
function isQueryString(key: unknown): key is QueryString {
  const keys: string[] = Object.values(QueryString)
  return typeof key === 'string' && keys.includes(key)
}

export interface DesignQuery {
  id: string
  type: 'sticker'
}

function isDesignType(type: string): type is DesignQuery['type'] {
  return type === 'sticker'
}

export function getDesignQuery(query: string): DesignQuery | null {
  const sepIndex = query.indexOf('_')
  const type = query.slice(0, Math.max(0, sepIndex))
  const id = query.slice(sepIndex + 1)
  if (!isDesignType(type)) {
    return null
  }
  return { type, id }
}

// TODO: 現階段是在一開始 @/router.ts update 他，之後做個 store 來記錄 cookie 跟 query string
export function updateUseRouter(_router: Router): void {
  router = _router
}

export async function hasValidShareDesign(): Promise<boolean> {
  if (router === null) {
    return false
  }
  await router.isReady()
  const urlQuery = getUrlQueryString()
  if (
    urlQuery.has(QueryString.ShareDesign) &&
    (!urlQuery.has(QueryString.ProductNavigation) || !urlQuery.has(QueryString.Product))
  ) {
    await setQueryStringShareDesignUuid(null)
    return false
  }
  return true
}

function getUrlQueryString(): Map<QueryString, string> {
  // 因為 router 可能會還沒準備好就要拉資料了
  // 而且 isReady 是 async function 我不想讓 get query 變成 async function
  // 所以目前我先監聽 router 裡面的 query 是不是空的，如果是空的 object 就會去用 window.location.search 檢查是不是真的沒有 query string
  const queryObject: Array<[string, string]> =
    router !== null && Object.values(router.currentRoute.value.query).length !== 0
      ? Object.entries(router.currentRoute.value.query).filter(
          (item): item is [string, string] => typeof item[1] === 'string',
        )
      : [...new URLSearchParams(window.location.search).entries()]
  const queryString = new Map<QueryString, string>()
  for (const [key, value] of queryObject) {
    if (isQueryString(key)) {
      queryString.set(key, value)
    }
  }
  return queryString
}

let urlQuery: Map<QueryString, string> | null = null
async function updateQueryString(queryString: QueryString, value: null | string): Promise<void> {
  if (router === null) {
    return
  }
  await router.isReady()

  if (urlQuery === null) {
    urlQuery = getUrlQueryString()
  }
  if (value === null) {
    urlQuery.delete(queryString)
  } else {
    urlQuery.set(queryString, value)
  }
  await router
    .replace({
      query: Object.fromEntries(urlQuery.entries()),
      force: true,
    })
    .catch((error: unknown) => {
      sentry.error(`router replace query ${queryString} to ${value ?? 'null'} failed`, {
        urlQuery,
        error,
      })
    })
}

export function getQueryStringDeviceHandle(): null | string {
  return getUrlQueryString().get(QueryString.Device) ?? null
}

export async function setQueryStringDeviceHandle(device: string): Promise<void> {
  await updateQueryString(QueryString.Device, device)
}

export function getQueryStringDeviceColor(): null | string {
  return getUrlQueryString().get(QueryString.DeviceColor) ?? null
}

export async function setQueryStringDeviceColor(deviceColor: string): Promise<void> {
  await updateQueryString(QueryString.DeviceColor, deviceColor)
}

export function getQueryStringProductHandle(): null | string {
  return getUrlQueryString().get(QueryString.Product) ?? null
}

export async function setQueryStringProductHandle(product: string): Promise<void> {
  await updateQueryString(QueryString.Product, product)
}

export function getQueryStringProductColor(): null | string {
  return getUrlQueryString().get(QueryString.ProductColor) ?? null
}

export async function setQueryStringProductColor(productColor: string): Promise<void> {
  await updateQueryString(QueryString.ProductColor, productColor)
}

export function getQueryStringProductNavigation(): null | string {
  return getUrlQueryString().get(QueryString.ProductNavigation) ?? null
}

export async function setQueryStringProductNavigation(productColor: string): Promise<void> {
  await updateQueryString(QueryString.ProductNavigation, productColor)
}

export async function setQueryStringDesign(design: string): Promise<void> {
  await updateQueryString(QueryString.Design, design)
}

export function getQueryStringDiscountCode(): null | string {
  return getUrlQueryString().get(QueryString.DiscountCode) ?? null
}

export async function setQueryStringDiscountCode(discountCode: null | string): Promise<void> {
  await updateQueryString(QueryString.DiscountCode, discountCode)
}

export function getQueryStringCarrierBarcodeImageUrl(): null | string {
  return getUrlQueryString().get(QueryString.CarrierBarcodeImageUrl) ?? null
}

export async function setQueryStringCarrierBarcodeImageUrl(
  carrierBarcodeImageUrl: null | string,
): Promise<void> {
  await updateQueryString(QueryString.CarrierBarcodeImageUrl, carrierBarcodeImageUrl)
}

export function getQueryStringShareDesignUuid(): null | string {
  return getUrlQueryString().get(QueryString.ShareDesign) ?? null
}

export async function setQueryStringShareDesignUuid(uuid: null | string): Promise<void> {
  await updateQueryString(QueryString.ShareDesign, uuid)
}

export function getQueryStringArtworkType(): ArtworkType | null {
  const type = getUrlQueryString().get(QueryString.ArtworkType)
  return isArtworkType(type) ? type : null
}

export async function setQueryStringArtworkType(type: ArtworkType): Promise<void> {
  await updateQueryString(QueryString.ArtworkType, type)
}

export async function setQueryStringTags(tagList: Map<string, ChipData>): Promise<void> {
  const keyArray: string[] = []
  tagList.forEach((tag) => keyArray.push(tag.key))
  await updateQueryString(QueryString.Tags, keyArray.toString())
}

export function getQueryStringTags(): null | string[] {
  return getUrlQueryString().get(QueryString.Tags)?.split(',') ?? null
}

export function getQueryStringKeyword(): string {
  const keyword = getUrlQueryString().get(QueryString.Keyword)
  return typeof keyword === 'string' ? decodeURIComponent(keyword) : ''
}

export async function setQueryStringKeyword(keyword: null | string): Promise<void> {
  await updateQueryString(
    QueryString.Keyword,
    typeof keyword === 'string' ? encodeURIComponent(keyword) : null,
  )
}

export function getQueryStringForeignPhotoUrl(): null | string {
  return getUrlQueryString().get(QueryString.ForeignPhotoUrl) ?? null
}

export async function clearQueryStringForeignPhotoUrl(): Promise<void> {
  await updateQueryString(QueryString.ForeignPhotoUrl, null)
}

export interface BoundDeviceProductQueryString {
  readonly product_type: BoundDeviceCategoryType

  readonly device_handle: string

  readonly device_color?: string | undefined

  readonly product_handle: string

  readonly product_color: string
}

export interface UnboundDeviceProductQueryString {
  readonly product_type: UnboundDeviceCategoryType

  readonly product_handle: string

  readonly product_color: string
}

export interface OldProductQueryString {
  readonly product_navigation: string

  readonly device: string

  readonly device_color?: string

  readonly product: string

  readonly product_color: string
}

export type QueryStringInfo = BoundDeviceProductQueryString | UnboundDeviceProductQueryString

export function getProductQueryString(): null | QueryStringInfo {
  const params = Object.fromEntries(new URLSearchParams(window.location.search))
  if (typia.is<BoundDeviceProductQueryString | UnboundDeviceProductQueryString>(params)) {
    return params
  } else if (typia.is<OldProductQueryString>(params)) {
    return migrateOldQueryString(params)
  }
  return null
}

function handleTransparentHandle(color: string): string {
  return color === '#EDEDED' || color === 'color-transparent' ? 'transparent' : color
}

function migrateOldQueryString(params: OldProductQueryString): null | QueryStringInfo {
  const newProductHandle = productHandleMigrationMap.get(params.product)
  if (newProductHandle === undefined) {
    return null
  }
  switch (params.product_navigation) {
    case 'airpods-case': {
      return {
        product_type: BoundDeviceCategoryType.AirPodsCase,
        device_handle: params.device,
        product_handle: newProductHandle,
        product_color: handleTransparentHandle(params.product_color),
      }
    }
    case 'airtag-case': {
      return {
        product_type: BoundDeviceCategoryType.AirTagCase,
        device_handle: params.device,
        product_handle: newProductHandle,
        product_color: handleTransparentHandle(params.product_color),
      }
    }
    case 'aquastand': {
      return {
        product_type: UnboundDeviceCategoryType.AquaStand,
        product_handle: newProductHandle,
        product_color: handleTransparentHandle(params.product_color),
      }
    }
    case 'grip': {
      return {
        product_type: UnboundDeviceCategoryType.Grip,
        product_handle: newProductHandle,
        product_color: handleTransparentHandle(params.product_color),
      }
    }

    case 'ipad-case': {
      return {
        product_type: BoundDeviceCategoryType.IPadCase,
        device_handle: params.device,
        device_color:
          params.device_color === undefined
            ? params.device_color
            : handleTransparentHandle(params.device_color),
        product_handle: newProductHandle,
        product_color: handleTransparentHandle(params.product_color),
      }
    }
    case 'phone-case': {
      return {
        product_type: BoundDeviceCategoryType.PhoneCase,
        device_handle: params.device,
        device_color: params.device_color,
        product_handle: newProductHandle,
        product_color: params.product_color,
      }
    }
  }
  return null
}

export function setProductQueryString(state: ProductStateWithSelectedProduct): void {
  upsertOrDeleteQueryString(
    typia.is<BoundDeviceProductState>(state)
      ? {
          product_type: state.categoryType,
          device_handle: state.device.handle,
          device_color: state.color?.handle ?? null,
          product_handle: state.selectedProduct.product.handle,
          product_color: state.selectedProduct.variant.color.handle,
        }
      : {
          product_type: state.categoryType,
          product_handle: state.selectedProduct.product.handle,
          product_color: state.selectedProduct.variant.color.handle,
        },
  )
}

function upsertOrDeleteQueryString(newQueryString: Record<string, null | string>): void {
  const url = new URL(location.href)
  for (const [key, value] of Object.entries(newQueryString)) {
    if (typeof value === 'string') {
      url.searchParams.set(key, value)
    } else {
      url.searchParams.delete(key)
    }
  }
  history.replaceState(null, '', url)
}
